import classNames from "classnames";
import React from "react";
interface Props {
  name?: string;
  url?: string;
  DocumentUrl?: string;
  classnamesvalues?: string;
}
export const ResearchComponent: React.FC<Props> = ({
  name,
  url,
  DocumentUrl,
  classnamesvalues,
}) => {
  return (
    <div
      className={classNames(
        "col-span-12 md:col-start-auto md:col-span-3 sm:col-span-4 ",
        "mt-60px lg:mt-0",
        classnamesvalues
      )}
    >
      <div className="w-mobile-12-cols md:w-tablet-4-cols lg:w-desktop-3-cols relative flex-grow-0 flex-shrink-0">
        <img src={url} alt="Logo" className="m-auto" />
      </div>
      <p className="pt-20px text-center text-left ga-pdflist-title">{name}</p>
      <a
        type="button"
        className="col-start-5 col-span-2 grid grid-gap grid-cols-2 m-auto pb-40px"
        href={DocumentUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="bg-right-pattern bg-right bg-no-repeat bg-28px h-50px" />
        <span className="self-center text-left read-more">Download</span>
      </a>
    </div>
  );
};
