/* eslint-disable no-self-assign */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/require-await */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/no-empty-interface */
// import { NextPage, GetStaticPaths } from "next";
import React from "react";
// import { SitePluginFieldsEnum } from "../../../generated/graphql-types";

// export const getStaticProps: GetStaticProps = async () => {};

// export const getStaticPaths: GetStaticPaths = async (s: any) => {
//   console.log("TESTing");
//   console.log(s);
// };

// export const TypePage: NextPage<TypePageProps> = (type) => {
//   let test = "";
//   test = type;
//   console.log(test);
//   return test.slug;
// };
// export default TypePage;

/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { graphql, PageProps } from "gatsby";
import { Hero, Statement } from "../../../components";
// eslint-disable-next-line import/named
import { ResearchComponent } from "../../../components/ResearchDocument";
import { buildMenu, Layout } from "../../../layouts";
// import { t } from "i18next";
export interface TypePageProps {
  slug: string;
}

const ResearchDocument: React.FC<
  PageProps<
    Pick<
      Query,
      | "allGraphCmsResearchDocument"
      | "graphCmsSectorPage"
      | "allGraphCmsSectorPage"
    >
  >
> = ({
  path,
  slug,
  data: {
    allGraphCmsResearchDocument: { nodes },
    graphCmsSectorPage: sectorPage,
    allGraphCmsSectorPage: { edges },
  },
}) => {
  let router = slug || "";
  let page = 0;
  router = router?.replace("research", "");
  router = router?.replaceAll("-", " ");
  router = router?.trim();
  const img_title = router;
  let title = router;
  // let string123 = "";
  // title.split(" ").map((n) => {
  //   console.log(n[0]);
  //   n[0] = "t";
  //   console.log(n);
  //   string123 += n;
  // });
  // console.log(string123);
  title = router.replace(/\s(.)/g, function ($1) {
    return $1.toUpperCase();
  });
  title = title.charAt(0).toUpperCase() + title.slice(1);
  router = router?.toLowerCase();
  let special = 0;
  nodes.map((n) =>
    n.sectorPages.map((S) => {
      if (router.toLowerCase().includes("mining")) {
        special = 1;
      }
      // if (S.sector?.trim().toLowerCase() == router) {
      if (S.sector?.trim().toLowerCase().includes(router)) {
        page += 1;
      } else {
        page = page;
      }
    })
  );
  if (typeof window === "undefined" || !window.document) {
    return;
  }

  if (page == 0) {
    // window.location.href = "../../" + slug;
  }
  let socialHead2 = "";
  let titleForHeading = "";
  edges.map((values) => {
    if (values.node.sector?.trim().toLowerCase().search(img_title) == -1) {
    } else {
      socialHead2 = values.node.heroImage?.url;
      titleForHeading = values.node.sector;
      return;
    }
  });
  const Image = {
    layout: "constrained",
    backgroundColor: "#282828",
    images: {
      fallback: {
        sizes: "(min-width: 1280px) 1280px, 100vw",
        src: { socialHead2 },
        srcSet: socialHead2,
        sources: [],
        layout: "constrained",
        width: 1280,
      },
    },
    width: 1280,
    height: 720,
  };

  if (titleForHeading == "") {
    titleForHeading = title;
  }
  const heading =
    "A collection of analysis, research and stories about our capabilities from our " +
    titleForHeading +
    " experts.";
  console.log("comming research slug");
  if (!ResearchDocument) return null;
  return (
    <Layout
      pagePath={path}
      menu={buildMenu(sectorPage?.menu)}
      footerMenu={buildMenu(sectorPage?.footerMenu)}
      keywords={sectorPage?.keywords}
      title={
        titleForHeading +
        " | Partners in Performance " +
        titleForHeading +
        "  Insights"
      }
      description={sectorPage?.description}
    >
      <Hero
        title={"Partners in Performance  " + titleForHeading + " Insights"}
        image={Image}
        short={true}
        treatment={sectorPage?.heroTreatment}
        showSearch={true}
      />
      <Statement
        title=""
        headline={heading}
        id={""}
        cleanedMessage={[
          {
            type: "paragraph",
            children: [
              {
                className: "underline",
                type: "link",
                href: "/en/insights/",
                children: [
                  {
                    text: "Click here to browse all of our insights.",
                  },
                ],
              },
            ],
          },
        ]}
        className="researchClass"
      />
      <section
        data-component="ResearchComponent"
        className="relative max-width z-10 "
      >
        <div className="content-grid grid-flow-row-dense">
          {/* {nodes.map(
            (n) =>
            n.sectorPages.map((S) => {
              if (S.sector.trim().toLowerCase() == router) {
                <ResearchComponent
                  name={n.documentDescription}
                  url={n.documentThumbnail.url}
                  DocumentUrl={n.documents.url}
                />;
              } else {
                page = page;
                console.log(page);
              }
            })
          )} */}
          {nodes.map((test) =>
            test.sectorPages.map(
              (tt) =>
                // tt.sector.trim().toLowerCase() == router && (
                tt.sector?.trim().toLowerCase().includes(router) && (
                  <ResearchComponent
                    name={test.documentDescription}
                    url={test.documentThumbnail.url}
                    DocumentUrl={test.documents.url}
                  />
                )
            )
          )}

          {special == 1 &&
            nodes.map((test) =>
              test.sectorPages.map(
                (tt) =>
                  // tt.sector.trim().toLowerCase() == router && (
                  tt.sector?.trim().toLowerCase().includes("metals") && (
                    <ResearchComponent
                      name={test.documentDescription}
                      url={test.documentThumbnail.url}
                      DocumentUrl={test.documents.url}
                    />
                  )
              )
            )}

          {/* {nodes.map(
            (n) =>
              // eslint-disable-next-line react/jsx-key
              // if (n.documentLocate =="Capital-Project")
              n.documentLocate == "Capital-Project" && (
                <ResearchComponent
                  name={n.documentDescription}
                  url={n.documentThumbnail.url}
                  DocumentUrl={n.documents.url}
                />
              )
          )} */}
        </div>
      </section>
    </Layout>
  );
};

export default ResearchDocument;

export const IndexQuery = graphql`
  {
    allGraphCmsResearchDocument {
      nodes {
        id
        documentName
        documentLocate
        documentThumbnail {
          url
        }
        documentDescription
        documents {
          url
        }
        sectorPages {
          sector
        }
      }
    }
    graphCmsSectorPage {
      sectorType
      pageTitle
      keywords
      description
      menu {
        ...MenuFragment
      }
      businessChallengesIntroduction
      businessChallenges {
        headline
        id
        content {
          cleaned
        }
      }
      heroImage {
        ...ImageFragment
      }
      statement {
        cleaned
      }
      heroVideo {
        ...VideoFragment
      }
      heroTreatment
      id
      keyStatistic
      leadership {
        ...ProfileFragment
      }
      leadershipTitle
      quote
      quoteFrom {
        ...ProfileFragment
      }
      infographicIntroduction {
        cleaned
      }
      infographicDetails {
        cleaned
      }
      genericInfographicText {
        cleaned
      }
      sector
      slug
      statisticDetails
      storiesTitle
      storiesImage {
        ...ImageFragment
      }
      successes {
        __typename
        ... on GraphCMS_CaseStudy {
          ...CaseStudyReferenceFragment
        }
        ... on GraphCMS_Story {
          ...StoryReferenceFragment
        }
        ... on GraphCMS_Insight {
          ...InsightReferenceFragment
        }
      }
      form {
        ...FormFragment
      }
    }
    allGraphCmsSectorPage {
      edges {
        node {
          sector
          heroImage {
            id
            url
          }
          pageTitle
        }
      }
    }
  }
`;
